import Vue from 'vue'
import VueRouter from 'vue-router'
Vue.use(VueRouter)
const routes = [
  {
    path: '/test',
    component: () => import('@/views/appTest')
  },{
    path: '/index',
    component: () => import('@/views/indexHome')
  },{
    path: '/',
    redirect: '/index'
  },{
    path: '/help',
    component: () => import('@/views/helpDocs')
  },{
    path: '/tools',
    component: () => import('@/views/appTool')
  },{
    path: '/login',
    component: () => import('@/views/member/userLogin')
  },{
    path: '/usercenter',
    component: () => import('@/views/member/userCenter'),
    redirect: '/userorder',
    children: [
      {
        path: '/main',
        component: () => import('@/views/member/userMain')
      },
      {
        path: '/userorder',
        component: () => import('@/views/member/order/userOrder')
      }
      //     {
      //       path: '/userpay',
      //       component: userPay
      //     }
    ]
  }
]

const router = new VueRouter({
  routes
})

// 解决同一路由加载两遍报错问题
const originalPush = VueRouter.prototype.push
VueRouter.prototype.push = function push (location) {
  return originalPush.call(this, location).catch(err => err)
}
export default router

// 路由导航卫士
router.beforeEach((to, from, next) => {
  // if (to.path == '/login') {
  //   return next()
  // }
  // const token = window.sessionStorage.getItem('token')
  // if (!token) {
  //   return next({ path: '/login' })
  // }
  // next()

  // const s1 = document.createElement('script')
  // s1.type = 'text/javascript'
  // s1.src = '//pv.sohu.com/cityjson?ie=utf-8'
  // document.body.appendChild(s1)
  // const s2 = document.createElement('script')
  // s2.type = 'text/javascript'
  // s2.src = '//pyui.oss-cn-hangzhou.aliyuncs.com/js/stat.js'
  // document.body.appendChild(s2)
  // //移除
  // document.body.removeChild(s1)
  // document.body.removeChild(s2)
  /// //////////////////////////////////////////

  /// //////////////////////////////////////////
  next()
})
